const header = {
  // all the properties are optional - can be left empty or deleted
  homepage: 'https://yedo.com',
  title: 'Yedo.com',
}

const about = {
  // all the properties are optional - can be left empty or deleted
  name: 'Yedo.com',
  role: '',
  description: '',
  resume: '',
  social: null,
}

const projects = []

const skills = []

const contact = {
  // email is optional - if left empty Contact section won't show up
  email: '',
}

export { header, about, projects, skills, contact }
